import React, { useState } from "react";
import AppButton from "./AppButon";
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { withNamespaces } from "react-i18next";
// Import Social Profile Images
import realstate from "../../../../assets/images/brands/realstate.png";
import profit from "../../../../assets/images/brands/profit.png";
import agronomy from "../../../../assets/images/brands/agronomy.png";
import master from "../../../../assets/images/brands/master.png";
import avanx from "../../../../assets/images/brands/avanx.png";
import izeven from "../../../../assets/images/brands/izeven.png";
import IUP from "../../../IUP";
import { useSelector } from "react-redux";

const AppDropDown = (props) => {
  const {user} = useSelector((state) => state.user);
  const [menuapps, setMenuApps] = useState(false);

  const toggleApps = () => {
    setMenuApps((prevState) => !prevState);
  };

if (!user.subscription || user.expired || !user.ipa) {
  return null;
}

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menuapps}
        toggle={toggleApps}
        className="d-sm-inline-block d-lg-inline-block ms-1"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item noti-icon waves-effect"
        >
          <i className="ri-apps-2-line"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
          <div className="px-lg-2">
            <Row className="g-0">
              <Col>
                <AppButton
                  link={"https://master.izeven.com/"}
                  image={master}
                  textStyle={"blue-text"}
                  title={"Master"}
                />
              </Col>
              <Col>
                <AppButton
                  link={"https://chat.whatsapp.com/H4rDckDd9yeFE01glIKOrF"}
                  image={profit}
                  textStyle={"profit-text"}
                  title={"Profit"}
                />
              </Col>
              <Col>
                <AppButton
                  link={"https://realestate.izeven.com/"}
                  image={realstate}
                  textStyle={"realstate-text"}
                  title={"Real Estate"}
                />
              </Col>
            </Row>

            <Row className="g-0">
              <Col>
                <AppButton
                  link={"https://chat.whatsapp.com/CGCQRG71yd13YCGpZEK4EY"}
                  image={izeven}
                  textStyle={"izeven-text"}
                  title={"iZeven"}
                />
              </Col>
              <Col>
                <AppButton
                  link={"https://agronomy.izeven.com/"}
                  image={agronomy}
                  textStyle={"agronomy-text"}
                  title={"Agronomy"}
                />
              </Col>
            </Row>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withNamespaces()(AppDropDown);